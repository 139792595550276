import { getBrowser } from '@/helpers/getBrowser'
import ABTests from '@/helpers/ABTests'

export default {
  data() {
    return {
      email: '',
      isOpenFormSidebar: false,
      form: '',
      header: '',
      browser: '',
      isAutotest: false,
      isABTestEnabled: false,
      btnCreate: {
        text: 'Попробовать бесплатно',
        href: 'javascript:void(0);',
      },
      btnPromoTry: {
        href: '/user',
      },
      btnPromoCreate: {
        href: '/user/participant',
      },
      abFormSelectorVariant: '0',
    }
  },
  mounted() {
    this.browser = getBrowser().toLowerCase()

    // при наличии этих значений в адресе отправляем на формы регистрации
    if (['#form', '#form-webinar', '#form-meetings', '#form-participant'].includes(location.hash)) {
      location.href = '/user/';
    }

    if (this.$route.query.autotest === '1') {
      this.isAutotest = true
    } else {
      // ab-tests goes here:
      this.abFormSelectorVariant = ABTests.getABFormSelectorVariant()
      this.isABTestEnabled = this.abFormSelectorVariant !== '0';
    }
  },
  methods: {
    showFormSidebar(header, form, params = {}) {
      this.$eventBus.$emit('show-sidebar', {
        form,
        header,
        params,
      })
    },
  },
}
